import BasicStackedBarChartWidget from "../components/widgets/analytics/basicStackedBarChart";
import BarChartWidget from "../components/widgets/barChartWidget";
import BarLineComboWidget from "../components/widgets/barComboWidget";
import CandleStickWidget from "../components/widgets/candleStickWidget";
import DemandForecastWidget from "../components/widgets/demandForecastWidget";
import DonutChartWidget from "../components/widgets/donutChartWidget";
import DonutSwitchWidget from "../components/widgets/donutSwitchWidget";
import DormantChartWidget from "../components/widgets/dormantChartWidget";
import FunnelChartWidget from "../components/widgets/funnelChartWidget";
import GaugeChartWidget from "../components/widgets/gaugeChartWidget";
import GaugeGroupWidget from "../components/widgets/gaugeGroupWidget";
import LineChartWidget from "../components/widgets/lineChartWidget";
import ListWidget from "../components/widgets/listWidget";
import LostOpportunitiesWidget from "../components/widgets/lostOpportunitiesWidget";
import MagicQuadrantWidget from "../components/widgets/magicQuadrantWidget";
import MapBubbleWidget from "../components/widgets/mapBubbleWidget";
import MultiSeriesComboWidget from "../components/widgets/multiSeriesComboWidget";
import MultiSeriesLineChartWidget from "../components/widgets/multiSeriesLineChartWidget";
import MultisetBarChartSwitchWidget from "../components/widgets/multisetBarChartSwitchWidget";
import MultiSetBarChartWidget from "../components/widgets/multiSetBarChartWidget";
import RadialPolarBarWidget from "../components/widgets/radialPolarBarWidget";
import SankeyChartWidget from "../components/widgets/sankeyChartWidget";
import ScatterPlotChartWidget from "../components/widgets/scatterPlotLocationChartWidget";
import SimpleIconWidget from "../components/widgets/simpleIconWidget";
import SmallNumberCostWidget from "../components/widgets/smallNumberCostWidget";
import SmallNumberCountWidget from "../components/widgets/smallNumberCountWidget";
import SmallNumberMultiStatsWidget from "../components/widgets/smallNumberMultiStatsWidget";
import SmallTabbedNumberWidget from "../components/widgets/smallTabbedNumberWidget";
import SmallTwinNumberWidget from "../components/widgets/smallTwinNumberWidget";
import StackedBarChartSwitchWidget from "../components/widgets/stackedBarChartSwitchWidget";
import StackedBarChartWidget from "../components/widgets/stackedBarChartWidget";
import SunburstChartWidget from "../components/widgets/sunburstChartWidget";
import TrendsWidget from "../components/widgets/trendsWidget";
import UnknownWidget from "../components/widgets/unknownWidget";
import WaterfallChartWidget from "../components/widgets/waterfallChartWidget";
import BSTableWidget from "../features/businessSummary/components/bsTableWidget";
import { WIDGET_TYPE } from "../types/widgets";

export const WidgetsConfigurations = {
  [WIDGET_TYPE.NUM_COUNT_SM]: {
    component: SmallNumberCountWidget,
    layout: {
      lg: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: 7,
        maxW: 3,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      md: {
        h: 7,
        w: 2,
        minH: 7,
        minW: 2,
        maxH: 7,
        maxW: 2,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      sm: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: 7,
        maxW: 3,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      xs: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: 7,
        maxW: 3,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      xxs: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: 7,
        maxW: 3,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
    },
  },
  [WIDGET_TYPE.DONUT_MD]: {
    component: DonutChartWidget,
    layout: {
      lg: {
        h: 10,
        w: 6,
        minH: 9,
        minW: 6,
        maxH: 14,
        maxW: 9,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 10,
        w: 5,
        minH: 8,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.BAR_MD]: {
    component: BarChartWidget,
    layout: {
      lg: {
        h: 10,
        w: 6,
        minH: 10,
        minW: 4,
        maxH: 11,
        maxW: 12,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 10,
        w: 4,
        minH: 10,
        minW: 4,
        maxH: 11,
        maxW: 4,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: 10,
        minW: 3,
        maxH: 11,
        maxW: 4,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: 10,
        minW: 4,
        maxH: 10,
        maxW: 4,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: 10,
        minW: 2,
        maxH: 10,
        maxW: 2,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.MAP_BUBBLE_LG]: {
    component: MapBubbleWidget,
    layout: {
      lg: {
        h: 9,
        w: 12,
        minH: 9,
        minW: 6,
        maxH: 11,
        maxW: 12,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 10,
        w: 10,
        minH: 9,
        minW: 5,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.NUM_DUAL_SM]: {
    component: SmallTwinNumberWidget,
    layout: {
      lg: {
        h: 7,
        w: 6,
        minH: 7,
        minW: 6,
        maxH: 7,
        maxW: 6,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      md: {
        h: 6,
        w: 4,
        minH: 6,
        minW: 4,
        maxH: 6,
        maxW: 4,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      sm: {
        h: 7,
        w: 6,
        minH: 7,
        minW: 6,
        maxH: 7,
        maxW: 6,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      xs: {
        h: 7,
        w: 4,
        minH: 7,
        minW: 4,
        maxH: 7,
        maxW: 4,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      xxs: {
        h: 7,
        w: 2,
        minH: 7,
        minW: 2,
        maxH: 7,
        maxW: 2,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
    },
  },
  [WIDGET_TYPE.NUM_TAB_SM]: {
    component: SmallTabbedNumberWidget,
    layout: {
      lg: {
        h: 7,
        w: 6,
        minH: 7,
        minW: 6,
        maxH: 7,
        maxW: 6,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      md: {
        h: 6,
        w: 4,
        minH: 6,
        minW: 4,
        maxH: 6,
        maxW: 4,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      sm: {
        h: 7,
        w: 6,
        minH: 7,
        minW: 6,
        maxH: 7,
        maxW: 6,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      xs: {
        h: 7,
        w: 4,
        minH: 7,
        minW: 4,
        maxH: 7,
        maxW: 4,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      xxs: {
        h: 7,
        w: 2,
        minH: 7,
        minW: 2,
        maxH: 7,
        maxW: 2,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
    },
  },
  [WIDGET_TYPE.LINE_LG]: {
    component: LineChartWidget,
    layout: {
      lg: {
        h: 10,
        w: 12,
        minH: 9,
        minW: 4,
        maxH: 11,
        maxW: 12,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 10,
        w: 5,
        minH: 9,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.BAR_STACKED_MD]: {
    component: StackedBarChartWidget,
    layout: {
      lg: {
        h: 10,
        w: 12,
        minH: 9,
        minW: 4,
        maxH: 11,
        maxW: 12,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 9,
        w: 10,
        minH: 9,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.BAR_LINE_COMBO_LG]: {
    component: BarLineComboWidget,
    layout: {
      lg: {
        h: 10,
        w: 12,
        minH: 9,
        minW: 4,
        maxH: 11,
        maxW: 12,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 10,
        w: 10,
        minH: 9,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.LINE_MULTISERIES_LG]: {
    component: MultiSeriesLineChartWidget,
    layout: {
      lg: {
        h: 10,
        w: 12,
        minH: 9,
        minW: 4,
        maxH: 11,
        maxW: 12,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 10,
        w: 5,
        minH: 9,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.DONUT_DUAL_LG]: {
    component: DonutSwitchWidget,
    layout: {
      lg: {
        h: 10,
        w: 6,
        minH: 9,
        minW: 6,
        maxH: 14,
        maxW: 9,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 10,
        w: 5,
        minH: 8,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.BAR_MULTISET_MD]: {
    component: MultiSetBarChartWidget,
    layout: {
      lg: {
        h: 10,
        w: 12,
        minH: 9,
        minW: 4,
        maxH: 11,
        maxW: 12,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 9,
        w: 10,
        minH: 9,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.TRENDS_LG]: {
    component: TrendsWidget,
    layout: {
      lg: {
        h: 10,
        w: 12,
        minH: 9,
        minW: 4,
        maxH: 11,
        maxW: 12,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 9,
        w: 10,
        minH: 9,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.NUM_MULTI_STAT_SM]: {
    component: SmallNumberMultiStatsWidget,
    layout: {
      lg: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: 7,
        maxW: 3,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      md: {
        h: 7,
        w: 2,
        minH: 7,
        minW: 2,
        maxH: 7,
        maxW: 2,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      sm: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: 7,
        maxW: 3,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      xs: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: 7,
        maxW: 3,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      xxs: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: 7,
        maxW: 3,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
    },
  },
  [WIDGET_TYPE.NUM_COST_SM]: {
    component: SmallNumberCostWidget,
    layout: {
      lg: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: 7,
        maxW: 3,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      md: {
        h: 7,
        w: 2,
        minH: 7,
        minW: 2,
        maxH: 7,
        maxW: 2,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      sm: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: 7,
        maxW: 3,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      xs: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: 7,
        maxW: 3,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      xxs: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: 7,
        maxW: 3,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
    },
  },
  [WIDGET_TYPE.SCATTER_PLOT_LG]: {
    component: ScatterPlotChartWidget,
    layout: {
      lg: {
        h: 12,
        w: 12,
        minH: 9,
        minW: 6,
        maxH: 14,
        maxW: 9,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 11,
        w: 10,
        minH: 8,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.SANKEY_CHART_LG]: {
    component: SankeyChartWidget,
    layout: {
      lg: {
        h: 12,
        w: 12,
        minH: 9,
        minW: 6,
        maxH: 14,
        maxW: 9,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 11,
        w: 10,
        minH: 8,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.RADIAL_POLAR_BAR_LG]: {
    component: RadialPolarBarWidget,
    layout: {
      lg: {
        h: 10,
        w: 6,
        minH: 9,
        minW: 6,
        maxH: 14,
        maxW: 9,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 10,
        w: 5,
        minH: 8,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.GAUGE_CHART_MD]: {
    component: GaugeChartWidget,
    layout: {
      lg: {
        h: 8,
        w: 3,
        minH: 8,
        minW: 3,
        maxH: 10,
        maxW: 4,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 8,
        w: 3,
        minH: 8,
        minW: 3,
        maxH: 10,
        maxW: 4,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 8,
        w: 3,
        minH: 8,
        minW: 3,
        maxH: 10,
        maxW: 4,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 8,
        w: 4,
        minH: 8,
        minW: 4,
        maxH: 10,
        maxW: 4,
        isResizable: true,
        isDraggable: true,
      },
      xxs: {
        h: 8,
        w: 2,
        minH: 8,
        minW: 2,
        maxH: 10,
        maxW: 3,
        isResizable: true,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.CANDLESTICK_CHART_LG]: {
    component: CandleStickWidget,
    layout: {
      lg: {
        h: 12,
        w: 12,
        minH: 9,
        minW: 6,
        maxH: 14,
        maxW: 9,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 11,
        w: 10,
        minH: 8,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.MAGIC_QUADRANT_LG]: {
    component: MagicQuadrantWidget,
    layout: {
      lg: {
        h: 12,
        w: 12,
        minH: 9,
        minW: 6,
        maxH: 14,
        maxW: 9,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 11,
        w: 10,
        minH: 8,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.FORECAST_CHART_LG]: {
    component: DemandForecastWidget,
    layout: {
      lg: {
        h: 12,
        w: 12,
        minH: 9,
        minW: 6,
        maxH: 14,
        maxW: 9,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 11,
        w: 10,
        minH: 8,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.FUNNEL_CHART_LG]: {
    component: FunnelChartWidget,
    layout: {
      lg: {
        h: 10,
        w: 6,
        minH: 9,
        minW: 4,
        maxH: 11,
        maxW: 12,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 10,
        w: 5,
        minH: 8,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.LIST_MD]: {
    component: ListWidget,
    layout: {
      lg: {
        h: 10,
        w: 6,
        minH: 9,
        minW: 4,
        maxH: 12,
        maxW: 12,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 10,
        w: 5,
        minH: 8,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.DORMANT_CHART_MD]: {
    component: DormantChartWidget,
    layout: {
      lg: {
        h: 10,
        w: 6,
        minH: 9,
        minW: 4,
        maxH: 11,
        maxW: 12,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 10,
        w: 5,
        minH: 8,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.LOST_OPPORTUNITIES_LG]: {
    component: LostOpportunitiesWidget,
    layout: {
      lg: {
        h: 12,
        w: 12,
        minH: 9,
        minW: 6,
        maxH: 14,
        maxW: 9,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 11,
        w: 10,
        minH: 8,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.MULTI_SERIES_COMBO_CHART]: {
    component: MultiSeriesComboWidget,
    layout: {
      lg: {
        h: 10,
        w: 12,
        minH: 9,
        minW: 4,
        maxH: 11,
        maxW: 12,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 10,
        w: 5,
        minH: 9,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.SUNBURST_CHART_MD]: {
    component: SunburstChartWidget,
    layout: {
      lg: {
        h: 10,
        w: 6,
        minH: 9,
        minW: 6,
        maxH: 14,
        maxW: 9,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 10,
        w: 5,
        minH: 8,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.BAR_MULTISET_SWITCH_MD]: {
    component: MultisetBarChartSwitchWidget,
    layout: {
      lg: {
        h: 10,
        w: 12,
        minH: 9,
        minW: 4,
        maxH: 11,
        maxW: 12,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 9,
        w: 10,
        minH: 9,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.WATERFALL_CHART_LG]: {
    component: WaterfallChartWidget,
    layout: {
      lg: {
        h: 10,
        w: 12,
        minH: 9,
        minW: 4,
        maxH: 11,
        maxW: 12,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 10,
        w: 10,
        minH: 9,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.BAR_STACKED_SWITCH_MD]: {
    component: StackedBarChartSwitchWidget,
    layout: {
      lg: {
        h: 10,
        w: 12,
        minH: 9,
        minW: 4,
        maxH: 11,
        maxW: 12,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 9,
        w: 10,
        minH: 9,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.GAUGE_GROUP_LG]: {
    component: GaugeGroupWidget,
    layout: {
      lg: {
        h: 10,
        w: 12,
        minH: 9,
        minW: 4,
        maxH: 11,
        maxW: 12,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 9,
        w: 10,
        minH: 9,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.STACKED_BAR_CHART]: {
    component: BasicStackedBarChartWidget,
    layout: {
      lg: {
        h: 10,
        w: 12,
        minH: 9,
        minW: 4,
        maxH: 11,
        maxW: 12,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 9,
        w: 10,
        minH: 9,
        minW: 4,
        maxH: 12,
        maxW: 10,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 10,
        w: 3,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 10,
        w: 4,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xxs: {
        h: 10,
        w: 2,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
    },
  },
  [WIDGET_TYPE.BS_TABLE_WIDGET]: {
    component: BSTableWidget,
    layout: {
      lg: {
        h: 10,
        w: 1,
        minH: 9,
        minW: 1,
        maxH: 12,
        maxW: 2,
        isResizable: false,
        isDraggable: false,
      },
      md: {
        h: 10,
        w: 1,
        minH: 8,
        minW: 1,
        maxH: 12,
        maxW: 2,
        isResizable: false,
        isDraggable: false,
      },
      sm: {
        h: 10,
        w: 1,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: false,
      },
      xs: {
        h: 10,
        w: 1,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: false,
      },
      xxs: {
        h: 10,
        w: 1,
        minH: undefined,
        minW: undefined,
        maxH: undefined,
        maxW: undefined,
        isResizable: false,
        isDraggable: false,
      },
    },
  },
  [WIDGET_TYPE.SIMPLE_ICON_WIDGET]: {
    component: SimpleIconWidget,
    layout: {
      lg: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: 7,
        maxW: 3,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      md: {
        h: 7,
        w: 2,
        minH: 7,
        minW: 2,
        maxH: 7,
        maxW: 2,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      sm: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: 7,
        maxW: 3,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      xs: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: 7,
        maxW: 3,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
      xxs: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: 7,
        maxW: 3,
        isResizable: false,
        isDraggable: true,
        resizeHandles: [],
      },
    }
  },
  ["UNKNOWN_TYPE"]: {
    component: UnknownWidget,
    layout: {
      lg: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      md: {
        h: 6,
        w: 2,
        minH: 6,
        minW: 2,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      sm: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xs: {
        h: 7,
        w: 3,
        minH: 7,
        minW: 3,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
      xxs: {
        h: 7,
        w: 2,
        minH: 7,
        minW: 2,
        maxH: undefined,
        maxW: undefined,
        isResizable: true,
        isDraggable: true,
      },
    },
  },
};
