import { useEffect } from "react";
import usePusher from "../../helpers/hooks/usePusher";
import { usePub } from "../../helpers/hooks/pubSub";

const LocationPrivateEvents = ({ dashboardName, locationId }) => {
  const { pusherInstance } = usePusher();
  const publishMessage = usePub();

  useEffect(() => {
    let privateChannel = pusherInstance?.subscribe(
      `private-${dashboardName}_dashboard-location-${locationId}`
    );

    // Pusher Subscription Events
    privateChannel?.bind("pusher:subscription_error", () => {});

    // Dashboard Events
    privateChannel?.bind("dashboard-updated", (data) => {
      publishMessage(`dashboard_updated_${dashboardName}`, undefined);
    });

    return () => {
      privateChannel?.unsubscribe();
    };
  }, []);

  return <></>;
};

const DashboardPrivateEvents = ({ dashboardName, locationIds }) => {
  return (
    <>
      {locationIds.map((locationId) => (
        <LocationPrivateEvents
          key={crypto.randomUUID()}
          locationId={locationId}
          dashboardName={dashboardName}
        />
      ))}
    </>
  );
};

export default DashboardPrivateEvents;
