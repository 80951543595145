import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import styled from "styled-components";
import DashboardDataProvider from "../../components/dashboardDataProvider";
import PrimaryFilterHelper from "../../components/primaryFilterHelper";
import { RootState } from "../../state/store";

const Layout = () => {
  const { company_details } = useSelector((state: RootState) => state.company);
  const { locations } = useSelector((state: RootState) => state);

  const routeMatch = useParams();

  const showContent = useMemo(() => {
    if (company_details?.company_id && locations.length > 0) {
      return true;
    }
    return false;
  }, [company_details, locations.length]);

  return (
    <PrimaryFilterHelper>
      <DashboardDataProvider dashboard={routeMatch.dashboard}>
        <LayoutWrapper>{showContent ? <Outlet /> : null}</LayoutWrapper>
      </DashboardDataProvider>
    </PrimaryFilterHelper>
  );
};

export default Layout;

const LayoutWrapper = styled.div`
  margin-top: 80px;
`;
