export const lightTheme = {
  primary_color: "#0D8A6A",
  text_color: "#000000",
  body: "#F6F6F6",
  text: "#363537",
  ticker: {
    background_color: "#F9FAFB",
    border_bottom: "1px solid #F1F1F4",
    circle_fill: "#2E2E33",
  },
  tooltip: {
    background: "rgba(255, 255, 255, 0.87)",
    text: "#000000",
  },
  dropdown: {
    background_color: "#ffffff",
    color: "#000",
    disabled: {
      color: "#b2b2b2",
      background: "#dadada",
    },
  },
  date_range_picker: {
    preset: {
      color: "#000000",
      background: "#fafafa",
      custom_button: "#e2f0db",
      hover: "#e1e1e1",
    },
    custom: {
      color: "#000000",
      background: "#f9f9f9",
      border: "1px solid #f6f6f6",
    },
  },
  button: {
    primary_color: "#0D8A6A",
    primary_border_color: "#36474e",
  },
  button_plain: {
    text: "#363537",
    background: "#ffffff",
    border: "#363537",
    hover: "#DADDE2",
    hover_text: "#363537",
  },
  background: "#E5E5E5",
  widget: {
    background: "#ffffff",
    tableHeader: "#D8EDEA",
    outline: "#acd5ca",
  },
  widget_group: {
    background: "#F0F0F0",
    border: "#F0F0F0",
    tableHeader: "#D8EDEA",
    outline: "#acd5ca",
  },
  modal: {
    background: "#ffffff",
    text: "#000",
    hover: "#b3b3b3"
  },
  pivot_table: {
    background_color: "#ffffff",
    field_list_props: {
      border_color: "#b3b3b3 ",
    },
    table_props: {
      border_color: "#292929",
      header_background_color: "#ececec",
    },
    calculated_field_props: {
      background_color: "#ffffff",
      input_field_background_color: "#fff",
    },
    chart_props: {
      label_color: "#050505",
    },
  },
  bs_title: "#D8EDEA",
  border_color: "#e9e9e9",
  tableBorder: "#B0BEC5",
  resetBorder: "#ffffff",
  partition_line: "1px solid #f2f2f2",
  table_cell_border: "#ececec",
  sticky_nav: "#fff",
  recommendation_note: {
    background: "#f9f9f9",
  },
  filter: {
    background: "#464646",
    color: "#363537",
  },
};

export const darkTheme = {
  primary_color: "#0D8A6A",
  text_color: "#000000",
  body: "#363537",
  text: "#d6d6d6",
  ticker: {
    background_color: "#444445",
    border_bottom: "1px solid #4c4c4d",
    circle_fill: "#6d6d6d",
  },
  tooltip: {
    background: "rgba(0, 0, 0, 0.87)",
    text: "#ffffff",
  },
  dropdown: {
    background_color: "#212121",
    color: "#fff",
    disabled: {
      color: "#969696",
      background: "#2d2d2d",
    },
  },
  date_range_picker: {
    preset: {
      color: "#ffffff",
      background: "#353535",
      custom_button: "#9bad91",
      hover: "#6e6e6e",
    },
    custom: {
      color: "#ffffff",
      background: "#4a4a4a",
      border: "1px solid #383636",
    },
  },
  button: {
    primary_color: "#0D8A6A",
    primary_border_color: "#36474e",
  },
  button_plain: {
    text: "#ffffff",
    background: "#212121",
    border: "#ffffff",
    hover: "#474747",
    hover_text: "#ffffff",
  },
  background: "#212121",
  widget: {
    background: "#212121",
    tableHeader: "#212121",
    outline: "#3e7667",
  },
  widget_group: {
    background: "#2a2a2a",
    border: "#2a2a2a",
    tableHeader: "#D8EDEA",
    outline: "#acd5ca",
  },
  modal: {
    background: "#212121",
    text: "#ffffff",
    hover: "#6d6d6d"
  },
  pivot_table: {
    background_color: "#333333",
    field_list_props: {
      border_color: "#ffffff"
    },
    calculated_field_props: {
      background_color: "#333333",
      input_field_background_color: "#272727",
    },
    table_props: {
      border_color: "#4e4c4c",
      header_background_color: "#2c2c2c",
    },
    chart_props: {
      label_color: "#ffffff",
    },
  },
  border_color: "#4e4c4c",
  tableBorder: "#363537",
  resetBorder: "#ffffff",
  bs_title: "#6B6B6B",
  partition_line: "1px solid #323232",
  table_cell_border: "#2e2e2e",
  sticky_nav: "#413f3f",
  recommendation_note: {
    background: "#282828",
  },
  filter: {
    background: "#e1e7ec",
    color: "#b4bec6",
  },
};
