import { createContext, useMemo, useState } from "react";
import { createDateFormatter } from "../comparison/utils";
import DashboardLivePresenceEvents from "./dashboardLivePresenceEvents";
import DashboardPresenceEvents from "./dashboardPresenceEvents";
import DashboardPrivateEvents from "./dashboardPrivateEvents";

export const DashboardEventsContext = createContext<{
  setLocationIds: any;
  setStartDate: any;
  setDashboard: any;
  isLiveDashboard: boolean;
}>(undefined as any);

const DashboardEvents = ({ children }) => {
  const [locationIds, setLocationIds] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [dashboard, setDashboard] = useState<string>("");

  const formatDate = createDateFormatter("yyyy-MM-dd");

  const isCurrentDay = useMemo(() => {
    return formatDate(new Date()) === startDate;
  }, [startDate]);

  return (
    <>
      {locationIds.length > 0 && startDate && dashboard ? (
        <>
          <DashboardPresenceEvents
            dashboardName={dashboard}
            locationIds={locationIds}
          />
          {isCurrentDay ? (
            <>
              <DashboardPrivateEvents
                dashboardName={dashboard}
                locationIds={locationIds}
              />
              <DashboardLivePresenceEvents
                dashboardName={dashboard}
                locationIds={locationIds}
              />
            </>
          ) : null}
        </>
      ) : null}
      <DashboardEventsContext.Provider
        value={{
          setLocationIds,
          setStartDate,
          setDashboard,
          isLiveDashboard: isCurrentDay,
        }}>
        {children}
      </DashboardEventsContext.Provider>
    </>
  );
};

export default DashboardEvents;
