import { format, parseISO } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import { useSortBy, useTable } from "react-table";
import styled from "styled-components";
import { reportExceptionToSentry } from "../../../services/sentry/utils"; 
import { formatDateWithTimezoneOffset } from "../../../helpers/utils";

export default function TabularList({ data, width, height }) {
  const [rowData, setRowData] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [showTableHeader, setShowTableHeader] = useState(true);
  const [aggregatedRowData, setAggregatedData] = useState([]);
  const tableInstance = useTable(
    { columns: columnData, data: rowData },
    useSortBy
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const getAggregatedData = (rowData, columnData) => {
    let emptyColumns = 0;
    let summaryData = columnData.map((c, i) => {
      if (i === 0) {
        return "Total";
      }
      if (c.type === "Number" && c.aggregate) {
        let total = rowData.reduce((total, d) => {
          return total + parseFloat(d[c.accessor]);
        }, 0);
        return total.toLocaleString();
      } else {
        emptyColumns++;
        return "";
      }
    });
    return emptyColumns === summaryData.length - 1 ? [] : summaryData;
  };

  const highlightCells = useMemo(() => {
    if (data && data?.config?.highlight_cells) {
      return data?.config?.highlight_cells;
    }
    return [];
  }, [data]);

  const getHeaderName = (headerName: string, valueFormatter: string ): string => {
    if (valueFormatter) {
      return `${headerName} (${valueFormatter})`;
    }
    return headerName;
  }

  useEffect(() => {
    if (data.data) {
      setAggregatedData(getAggregatedData(data.data.rows, data.data.columns));
      setRowData(data.data.rows);
      setColumnData(data.data.columns);
      setShowTableHeader(data.config.show_table_header);
    }
  }, [data]);

  const formatRowValue = (value, indexValue) => {
    let dateColumns = columnData.filter((data) => data.type === "Date");
    let offset = rowData[0]?.offset ?? null;
    let dateIndexes = [];
    for (let i = 0; i < dateColumns.length; i++) {
      let index = columnData.findIndex((data) => data == dateColumns[i]);
      dateIndexes.push(index);
    }
    let formattedValue = value.value;
    if (value.column.type === "Number") {
      formattedValue = parseFloat(value.value).toLocaleString();
    }
    if (value.column.type === "DateUnix") {
      if(offset === null) {
        reportExceptionToSentry({
          title: "Date formatting error",
          message: "Missing in row data",
          tags: { path: location.pathname },
        });
      } else {
        try {
          formattedValue = formatDateWithTimezoneOffset(
            value.value,
            offset,
            "yyyy-MM-dd hh:mm:ss a"
          );
        } catch (e) {
          reportExceptionToSentry({
            title: "Date formatting error",
            message: "Invalid offset value",
            tags: { path: location.pathname },
          });
          formattedValue = value.value;
        }
      }
    }
    if (dateIndexes.includes(indexValue) && value.value) {
      formattedValue = format(parseISO(formattedValue), "yyyy-MM-dd");
    }
    return formattedValue;
  };

  return (
    <TableWrapper style={{ width, height }}>
      <table
        className="bordered"
        {...getTableProps()}
        style={{ width: width - 15 }}>
        {showTableHeader ? (
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th><span>#</span></th>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {getHeaderName(column.header, column.value_formatter)}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <BsArrowDownShort />
                        ) : (
                          <BsArrowUpShort />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        ) : null}
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                <td><span>{rowIndex + 1}</span></td>
                {row.cells.map((cell, index) => {
                  const accessor = cell?.column?.id;
                  const value = cell.value;
                  let style = {};
                  const formatCell = highlightCells.find(
                    (d) => d.accessor == accessor && d.value == value
                  );
                  if (formatCell) {
                    if ("color" in formatCell) {
                      style["color"] = formatCell.color;
                    }
                    if ("background" in formatCell) {
                      style["background"] = formatCell.background;
                    }
                  }
                  return (
                    <td {...cell.getCellProps()} style={style}>
                      {formatRowValue(cell, index)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {aggregatedRowData.length ? (
          <tfoot>
            <tr>
              <td></td>
              {aggregatedRowData.map((data, i) => (
                <td key={i}>{data}</td>
              ))}
            </tr>
          </tfoot>
        ) : null}
      </table>
    </TableWrapper>
  );
}
const TableWrapper = styled.div`
  background-color: ${({ theme }) => theme.widget.background};
  padding-left: 10px;
  overflow: scroll;
  table {
    border-spacing: 0;
    background-color: ${({ theme }) => theme.widget.background};
    border-collapse: separate;
    > thead {
      height: 55px;
      z-index: 100;
      position: sticky;
      > tr {
        > th {
          margin-left: 20px;
          font-size: 16px;
          color: ${({ theme }) => theme.text};
          font-weight: 700;
          position: relative;
          white-space: nowrap;
          padding: 0 0.5rem;
          border-bottom: 2px solid #e4e4e4;
          top: 0px;
          position: sticky;
          background-color: ${({ theme }) => theme.widget.background};
          &:first-child {
            >span {
              opacity: 0.4;
            }
          }
        }
      }
    }
    > tbody {
      > tr {
        > td {
          font-size: 15px;
          font-weight: 400;
          line-height: 21.48px;
          padding: 0 0.5rem;
          white-space: nowrap;
          border-bottom: 1px solid #e4e4e4;
          &:first-child {
            >span {
              opacity: 0.4;
            }
          }
        }
        height: 49px;
      }
    }
    > tfoot {
      bottom: 0px;
      height: 55px;
      position: sticky;
      > tr {
        > td {
          margin-left: 20px;
          font-size: 16px;
          color: ${({ theme }) => theme.text};
          font-weight: 700;
          position: relative;
          white-space: nowrap;
          padding: 0 0.5rem;
          border-top: 2px solid #e4e4e4;
          top: 0px;
          position: sticky;
          background-color: ${({ theme }) => theme.widget.background};
        }
      }
    }
  }
`;
