import styled from "styled-components";
import monitor from "../../images/icons/monitor.svg";

const DashboardWidgetsFetchError = ({ retryRequest }) => {
  const handleRefresh = () => {
    if (retryRequest) {
      retryRequest();
    } else {
      console.error("no refetch method defined");
    }
  };

  return (
    <Wrapper>
      <div className="section">
        <img src={monitor} alt="broken-computer" />
        <div className="error-message">
          <span className="error-description">{"Unable to load widgets."}</span>
          <span className="error-description">
            {"Please "}
            <span className="retry-text" onClick={handleRefresh}>
              {"try again."}
            </span>
          </span>
        </div>
      </div>
    </Wrapper>
  );
};

export default DashboardWidgetsFetchError;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 60vh;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 56px;
    }
    .error-message {
      display: block;
      font-size: 18px;
      margin-top: 23px;
      .error-description {
        text-align: center;
        display: block;
      }
      .retry-text {
        cursor: pointer;
        color: #2b7bc5;
        text-decoration: underline;
      }
    }
  }
`;
