import { format, isDate, parse } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import { Dropdown, OverlayTrigger } from "react-bootstrap";
import { BsCalendar3, BsChevronDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import { formatDateString } from "../../helpers/utils";
import { RootState } from "../../state/store";
import { setTimeRangeFilter } from "../../state/features/filters/actions";
import DatePickerSection from "./datePickerSection";
import DatePresetSection from "./datePresetSection";
import DropdownFooterSection from "./footerSection";
import { useFilterContext } from "../../helpers/hooks/useFilterContext";
import { Tooltip } from "react-bootstrap";

const TooltipWrapper = ({ children, isDatePickerDisabled }) => {
  const currentDate = format(new Date(), "do MMMM yyyy");
  const currentTime = format(new Date(), "hh:mm aaa");
  if (isDatePickerDisabled) {
    return (
      <OverlayTrigger
        overlay={
          <StyledTooltip id="date-picker-freeze-help-text">
            {`This page only displays current data as of ${currentDate}`}
          </StyledTooltip>
        }>
        {children}
      </OverlayTrigger>
    );
  }
  return <>{children}</>;
};

const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    max-width: 500px;
  }
`;

const DateRangeSelectDropdown = ({ freeze = false }) => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const { dates: selectedDates, locations: selectedLocations } = useSelector(
    (state: RootState) => state.filters
  );
  const { applyFilter } = useFilterContext();

  const dropdownButtonRef = useRef(null);

  useEffect(() => {
    setStartDate(parse(selectedDates[0], "yyyy-MM-dd", new Date()));
    setEndDate(parse(selectedDates[1], "yyyy-MM-dd", new Date()));
  }, [selectedDates]);

  const dateRangeText = useMemo(
    () =>
      selectedDates[0] === selectedDates[1]
        ? formatDateString(selectedDates[0])
        : `${formatDateString(selectedDates[0])} - ${formatDateString(
            selectedDates[1]
          )}`,
    [selectedDates]
  );

  const pickedDateRange = useMemo(() => {
    let disableSubmit = true;
    let text = "";
    if (startDate === endDate) {
      return format(startDate, "dd MMMM yyyy");
    }
    if (startDate <= endDate) {
      disableSubmit = false;
    }
    text = `${format(startDate, "dd MMMM yyyy")} - ${format(
      endDate,
      "dd MMMM yyyy"
    )}`;
    return { text, disableSubmit };
  }, [startDate, endDate]);

  const toggleDropdownState = () => {
    if (dropdownButtonRef.current) {
      dropdownButtonRef.current.click();
    }
  };

  const applySelectedFilter = (dateRange) => {
    applyFilter({ type: "date", data: { dateRange, selectedLocations } });
    toggleDropdownState();
  };

  const handleOnSubmit = () => {
    let dateRange = [
      format(startDate, "yyyy-MM-dd"),
      format(endDate, "yyyy-MM-dd"),
    ];
    applySelectedFilter(dateRange);
  };

  const handleOnReset = () => {
    setStartDate(parse(selectedDates[0], "yyyy-MM-dd", new Date()));
    setEndDate(parse(selectedDates[1], "yyyy-MM-dd", new Date()));
    toggleDropdownState();
  };

  const disableApplyButton = useMemo(() => {
    const dateCompare = [
      format(startDate, "yyyy-MM-dd"),
      format(endDate, "yyyy-MM-dd"),
    ];
    if (
      dateCompare[0] == selectedDates[0] &&
      dateCompare[1] == selectedDates[1]
    ) {
      return true;
    }
    return false;
  }, [selectedDates, startDate, endDate]);

  const onHideReset = (isOpen) => {
    if (!isOpen) {
      setStartDate(parse(selectedDates[0], "yyyy-MM-dd", new Date()));
      setEndDate(parse(selectedDates[1], "yyyy-MM-dd", new Date()));
      toggleDropdownState();
    }
  };

  return (
    <Wrapper className="date-range-select-control">
      <Dropdown onToggle={onHideReset}>
        <TooltipWrapper isDatePickerDisabled={freeze}>
          <Dropdown.Toggle
            as={"button"}
            className="date-range-select-toggle"
            ref={dropdownButtonRef}
            disabled={freeze}>
            <BsCalendar3
              style={{ color: "#2B7BC5", fontSize: "24px", margin: "0 5px" }}
            />
            <span>{dateRangeText}</span>
            <BsChevronDown style={{ fontSize: "20px", margin: "0 5px" }} />
          </Dropdown.Toggle>
        </TooltipWrapper>
        <Dropdown.Menu className="date-range-select-dropdown">
          <DatePresetSection applyFilters={applySelectedFilter} />
          <DatePickerSection
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          <DropdownFooterSection
            pickedRangeText={pickedDateRange.text}
            onSubmit={handleOnSubmit}
            onReset={handleOnReset}
            disableSubmit={pickedDateRange.disableSubmit || disableApplyButton}
          />
        </Dropdown.Menu>
      </Dropdown>
    </Wrapper>
  );
};

export default DateRangeSelectDropdown;

const fadeInTop = keyframes`
  0% {
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-50);
            transform: translateY(-50);
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  .dropdown-toggle::after {
    content: none;
  }
  .date-range-select-toggle {
    color: ${({ theme }) => theme.text};
    background-color: ${({ theme }) => theme.widget.background};
    &:disabled {
      color: ${({ theme }) => theme.dropdown.disabled.color};
      background-color: ${({ theme }) => theme.dropdown.disabled.background};
    }
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    border: none;
    min-width: 208px;
    height: 44px;
    margin-left: 15px;
    transition: all 0.25s linear 0s;
    &::after {
      content: none;
    }
  }
  .date-range-select-dropdown {
    animation: ${fadeInTop} 0.2s;
    min-width: 650px;
    padding: 20px;
    border: 1px solid ${({ theme }) => theme.border_color};
    border-radius: 20px;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
  }
`;
