import { useLocation } from "react-router-dom";
import ThemeToggle from "../themeToggle";
import { useDispatch, useSelector } from "react-redux";
import { CustomDispatch, RootState } from "../../state/store";
import { navigationOperations } from "../../state/features/navigation";
import styled from "styled-components";
import { useFilterNavigate } from "../../helpers/hooks/useFilterNavigate";
import Logo from "./logo";
import { ENTITY_LOCK_TYPE } from "../../types/visibility";
import { useEffect, useMemo, useRef, useState } from "react";
import { NavItemIcon } from "./navItemIcon";
import {
  Navbar as BSNavbar,
  Nav as BSNav,
  Offcanvas,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { PiDotsThreeCircleFill } from "react-icons/pi";
import { authOperations } from "../../state/features/auth";

const Navbar = () => {
  const companyName = useSelector(
    (state: RootState) => state.company?.company_details?.company_name ?? ""
  );
  const { dashboards } = useSelector((state: RootState) => state.dashboard);
  const { enableCompanySwitch } = useSelector((state: RootState) => state.auth);
  const navigateTo = useFilterNavigate();
  const dispatch: CustomDispatch = useDispatch();
  const { toggleCompanySwitcher } = authOperations;
  const [enableOverflowPopover, setEnableOverflowPopover] = useState(false);

  const companyNameRef = useRef()

  const filterUnauthorizedDashboards = (data) => {
    return data.filter(
      (d) =>
        ![ENTITY_LOCK_TYPE.INVISIBLE, ENTITY_LOCK_TYPE.UNAUTHORIZED].includes(
          d.visibility
        )
    );
  };

  const location = useLocation();
  const [, path] = location.pathname.split("/");

  const accessibleDashboards = useMemo(
    () => filterUnauthorizedDashboards(dashboards),
    [dashboards]
  );

  const handleNavigation = (route) => {
    dispatch(navigationOperations.setNavigation(route));
    navigateTo(route);
  };

  const expand = "lg";

  const handleBrandingClick = () => {
    if(enableCompanySwitch) {
      toggleCompanySwitcher(true);
    }
  }

  useEffect(() => {
    if (companyName && companyNameRef.current) {
      const isOverflow =
        companyNameRef.current?.scrollWidth >
        companyNameRef.current?.clientWidth;
      setEnableOverflowPopover(isOverflow);
    }
  }, [companyName]);

  const renderTooltip = (props) => {
    return (
      <Popover {...props} className="company-name-popover">
        <Popover.Body>
          <h4>{companyName}</h4>
        </Popover.Body>
      </Popover>
    );
  };

  const renderCompanyName = ({ companyName, enableCompanySwitch }) => {
    return (
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        trigger={enableOverflowPopover ? ["hover", "focus"] : []}
        overlay={renderTooltip}>
        <span>
          <span className="company-name" ref={companyNameRef}>
            {companyName}
          </span>
          {enableCompanySwitch ? (
            <PiDotsThreeCircleFill
              style={{ marginLeft: "10px" }}
              color="#4C4C52"
            />
          ) : null}
        </span>
      </OverlayTrigger>
    );
  };

  return (
    <StyledBSNavbar bg="dark" variant="dark" expand="lg" fixed="top">
      <StyledBSNavbar.Brand>
        <div className="company-logo">
          <Logo />
        </div>
        <div
          className={`company-name-wrapper ${
            enableCompanySwitch ? "enable-selection" : ""
          }`}
          onClick={handleBrandingClick}>
          {renderCompanyName({ companyName, enableCompanySwitch })}
        </div>
      </StyledBSNavbar.Brand>
      <StyledBSNavbar.Toggle
        aria-controls={`offcanvasNavbar-expand-${expand}`}
      />
      <StyledBSNavbar.Offcanvas
        id={`offcanvasNavbar-expand-${expand}`}
        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
        placement="end">
        <Offcanvas.Body>
          <BSNav className="mr-auto">
            {accessibleDashboards.map((d, i) => {
              const isSelected = d.api_path == path;
              return (
                <BSNav.Link
                  key={d.api_path}
                  onClick={() => handleNavigation(`/${d.api_path}`)}>
                  <span
                    className={`dbrd-nav-item ${isSelected ? "selected" : ""}`}>
                    <span className="icon">
                      <NavItemIcon
                        name={d.identifier}
                        isSelected={isSelected}
                      />
                    </span>
                    <span className="dbrd-nav-title">{d.label}</span>
                  </span>
                </BSNav.Link>
              );
            })}
          </BSNav>
        </Offcanvas.Body>
      </StyledBSNavbar.Offcanvas>
      <div className="d-flex justify-content-right me-3">
        <ThemeToggle />
      </div>
    </StyledBSNavbar>
  );
};

export default Navbar;

const StyledBSNavbar = styled(BSNavbar)`
  z-index: 10;
  width: 100%;
  height: 80px;
  padding: 0px;
  .navbar-brand {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0px;
    .company-logo {
      background-color: rgb(32, 32, 36);
      border-right: 1px solid #494c52;
      height: 100%;
      align-content: center;
      padding: 0px 5px;
    }
    .company-name-wrapper {
      border-right: 1px solid #494c52;
      align-content: center;
      padding: 15px;
      font-weight: 700;
      width: auto;
      white-space: nowrap;
      height: 100%;
      &.enable-selection {
        cursor: pointer;
      }
      > span {
        .company-name {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          max-width: 200px;
          text-overflow: ellipsis;
        }
        > svg {
          position: relative;
          top: -6px;
        }
      }
    }
  }
  .navbar-nav {
    height: 100%;
    width: auto;
    &.spacer {
      width: 100%;
    }
  }
  .offcanvas {
    height: 100% !important;
    .offcanvas-body {
      height: 100%;
      width: 100%;
    }
  }
  .nav-link {
    align-content: center;
    text-align: center;
    border-right: 1px solid rgb(73, 76, 82);
    .dbrd-nav-item {
      font-size: 20px;
      padding: 0 5px;
      height: 100%;
      &:last-child {
        border-right: none;
      }
      &.selected {
        color: #0d8a6a;
      }
      .dbrd-nav-title {
        margin-left: 10px;
      }
    }
  }
`;
