import { EChartsOption } from "echarts-for-react";
import { useEffect, useState } from "react";
import BaseChart from "../../baseChart";

const CLASSIFICATION_TYPES = {
  star: {
    name: "Star",
    symbol: "diamond",
    color: "#91cc75",
    info: "High Popularity and High Profit",
    explanation:
      "They have a high-profit margin and are very popular with the customers.",
  },
  plow_horses: {
    name: "Plow Horses",
    symbol: "triangle",
    color: "#5a73be",
    info: "High Popularity and Low Profit",
    explanation:
      "They are items on the menu that are very popular with your customers but are quite expensive cost-wise.",
  },
  puzzles: {
    name: "Puzzles",
    symbol: "rectangle",
    color: "#fac858",
    info: "Low Popularity and High Profit",
    explanation:
      "They are items on your menu that have a high profit margin but are very difficult to sell.",
  },
  dog: {
    name: "Dog",
    symbol: "circle",
    color: "#ee6666",
    info: "Low Popularity and Low Profit",
    explanation:
      "They are the items on your menu that are high on food cost and not really popular amongst your customers.",
  },
};

const MagicQuadrantChart = ({ data, height, width, showLabels }) => {
  const [option, setOption] = useState<EChartsOption>({
    xAxis: {},
    yAxis: {},
    series: [],
  });

  const getAnalysisText = (
    xAxisLabel,
    yAxisLabel,
    xAxisMargin,
    yAxisMargin,
    xAxisValue,
    yAxisValue
  ) => {
    return `${xAxisValue >= xAxisMargin ? "High" : "Low"} ${xAxisLabel}, ${
      yAxisValue >= yAxisMargin ? "High" : "Low"
    } ${yAxisLabel}`;
  };

  useEffect(() => {
    if (data) {
      const { dogData, plowHorseData, puzzleData, starData } =
        transformData(data);
      let commonSeriesConfigs = {
        type: "scatter",
        symbolSize: 20,
        label: {
          show: showLabels,
          position: "bottom",
          formatter: "{b}",
        },
        markLine: {
          data: [
            {
              name: data.config.x_axis.mark_line.label,
              lineStyle: {
                color: "#655e5e",
              },
              tooltip: {
                show: true,
                formatter: (params) =>
                  `<div><b>${params.name}: </b> ${params.value}</div>`,
              },
              xAxis: data.config.x_axis.mark_line.value,
            },
            {
              name: data.config.y_axis.mark_line.label,
              lineStyle: {
                color: "#655e5e",
              },
              tooltip: {
                show: true,
                formatter: (params) =>
                  `<div><b>${params.name}: </b> ${params.value}</div>`,
              },
              yAxis: data.config.y_axis.mark_line.value,
            },
          ],
          symbol: ["none", "none"],
        },
        tooltip: {
          formatter: (params) => `
          <h5><b>${params.name}</b></h5>
          <div>
            <b>${data.config.x_axis.label}: </b>${params.value[0]
            .toFixed(2)
            .toLocaleString()}
            </div>
          <div>
            <b>${data.config.y_axis.label}: </b>${params.value[1]
            .toFixed(2)
            .toLocaleString()}
          </div>
          <div>
            <b>Classification: </b>${params.seriesName}
          </div>
          <div>
            <b>${getAnalysisText(
              data.config.x_axis.label,
              data.config.y_axis.label,
              data.config.x_axis.mark_line.value,
              data.config.y_axis.mark_line.value,
              params.value[0],
              params.value[1]
            )}</b>
          </div>`,
        },
      };
      setOption((o) => ({
        ...o,
        legend: {
          show: true,
          top: "10%",
          right: "30%",
          align: "left",
          itemHeight: "20",
          orient: "vertical",
          data: Object.entries(CLASSIFICATION_TYPES)
            .map((d) => d[1])
            .map((c) => ({
              name: c.name,
              icon: c.symbol,
              itemStyle: {
                color: c.color,
              },
              tooltip: {
                show: "true",
                // formatter: c.info,
                formatter: (params) => `
                  <h6><b>${params.name}</b></h6>
                  <div><p>${c.explanation}</p></div>
                  <div>${c.info}</div>
                `,
              },
            })),
        },
        tooltip: {
          show: true,
        },
        dataZoom: [{ type: "inside" }],
        grid: {
          // right: "15%",
          width: "50%",
        },
        xAxis: {
          name: data.config.x_axis.label,
          nameLocation: "middle",
          nameGap: 40,
        },
        yAxis: {
          name: data.config.y_axis.label,
          nameLocation: "middle",
          nameGap: 40,
        },
        series: [
          {
            ...commonSeriesConfigs,
            name: CLASSIFICATION_TYPES.dog.name,
            symbol: CLASSIFICATION_TYPES.dog.symbol,
            itemStyle: {
              color: CLASSIFICATION_TYPES.dog.color,
            },
            data: dogData.map((d) => ({ name: d.name, value: d.value })),
          },
          {
            ...commonSeriesConfigs,
            name: CLASSIFICATION_TYPES.plow_horses.name,
            symbol: CLASSIFICATION_TYPES.plow_horses.symbol,
            itemStyle: {
              color: CLASSIFICATION_TYPES.plow_horses.color,
            },
            data: plowHorseData.map((d) => ({
              name: d.name,
              value: d.value,
            })),
          },
          {
            ...commonSeriesConfigs,
            name: CLASSIFICATION_TYPES.puzzles.name,
            symbol: CLASSIFICATION_TYPES.puzzles.symbol,
            itemStyle: {
              color: CLASSIFICATION_TYPES.puzzles.color,
            },
            data: puzzleData.map((d) => ({ name: d.name, value: d.value })),
          },
          {
            ...commonSeriesConfigs,
            name: CLASSIFICATION_TYPES.star.name,
            symbol: CLASSIFICATION_TYPES.star.symbol,
            itemStyle: {
              color: CLASSIFICATION_TYPES.star.color,
            },
            data: starData.map((d) => ({ name: d.name, value: d.value })),
          },
        ],
      }));
    }
  }, [data, showLabels]);

  const transformData = (chartData) => {
    return {
      dogData: chartData.data.series.filter(
        (d) => d.classification_type === "dog"
      ),
      plowHorseData: chartData.data.series.filter(
        (d) => d.classification_type === "plow_horse"
      ),
      puzzleData: chartData.data.series.filter(
        (d) => d.classification_type === "puzzle"
      ),
      starData: chartData.data.series.filter(
        (d) => d.classification_type === "star"
      ),
    };
  };

  const handleClickEvent = () => {
    // TODO
  };

  return (
    <BaseChart
      height={height}
      width={width}
      options={option}
      chartType="scatter"
      onClick={handleClickEvent}
    />
  );
};

export default MagicQuadrantChart;
