import { endOfMonth, format, startOfMonth, subDays, subMonths } from "date-fns";

export const dateRangePresets = [
  {
    title: "Today",
    selected_dates: [
      format(new Date(), "yyyy-MM-dd"),
      format(new Date(), "yyyy-MM-dd"),
    ],
  },
  {
    title: "Yesterday",
    selected_dates: [
      format(subDays(new Date(), 1), "yyyy-MM-dd"),
      format(subDays(new Date(), 1), "yyyy-MM-dd"),
    ],
  },
  {
    title: "Last Month",
    selected_dates: [
      format(startOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd"),
      format(endOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd"),
    ],
  },
];
