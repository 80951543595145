import NewOrderDetailsModal from "../../../components/subReports/newOrderDetailsModal";
import OrderDetailsModal from "../../../components/subReports/orderDetailsModal";

interface ISelectSubReportProps {
  reportType: string;
  cellData: any;
}

const SelectSubReport = (props: ISelectSubReportProps) => {
  switch (props.reportType) {
    case "OrderDetails":
      return (
        <NewOrderDetailsModal
          selectedOrder={props.cellData}
          onClose={() => props.cellData.hideSubReport()}
        />
      );
    default:
      return <></>;
  }
};

export default SelectSubReport;
