import { format } from "date-fns";
import { Modal } from "react-bootstrap";
import { GrClose } from "react-icons/gr";
import { useSortBy, useTable } from "react-table";
import styled from "styled-components";

const WidgetTableModal = ({ showTableData, setShowTableData, tableData }) => {
  const tableInstance = useTable(
    { columns: tableData.columns, data: tableData.rows },
    useSortBy
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const formatCellData = (cellData) => {
    let content = cellData.value;
    if (cellData.column.type === "date") {
      content = format(new Date(cellData.value), "yyyy-MM-dd");
    }
    return content;
  };

  return (
    <Modal
      show={showTableData}
      className={"widget-report-modal"}
      dialogClassName="custom curved widget-report-wrapper"
      onHide={() => {}}
      size="xl"
      centered>
      <TableWrapper>
        <div className="modal-table">
          <div className="table-modal-header">
            <div>Details</div>
            <div className="close" onClick={() => setShowTableData(false)}>
              <GrClose className="icon"/>
            </div>
          </div>
          <div className="table-modal-body">
            <div className="table-modal-content">
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          <span>{column.render("header")}</span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell, index) => (
                          <td {...cell.getCellProps()}>{formatCellData(cell)}</td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </TableWrapper>
    </Modal>
  );
};

export default WidgetTableModal;

const TableWrapper = styled.div`
  height: 90vh;
  /* overflow: auto; */
  background-color: ${({ theme }) => theme.widget.background};
  border-radius: 20px;
  .table-modal-header {
    padding: 20px 20px 0px 20px;
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.widget.background};
    height: 50px;
    .close{
      cursor: pointer;
      .icon path{
        stroke: ${({ theme }) => theme.text};
      }
    }
  }
  .table-modal-body{
    overflow: auto;
    padding: 0px 10px 10px 10px; 
    box-shadow:  inset;
    display: block;
    height:  75vh;
    .table-modal-content {
      table {
        width: 100%;
        border-spacing: 0;
        background-color: ${({ theme }) => theme.widget.background};
        > thead {
          height: 55px;
          border-bottom: 1px solid ${({ theme }) => theme.body};
          background-color: ${({ theme }) => theme.widget.background};
          top: 0;
          position: sticky; 
          > tr {
            > th {
              margin-left: 20px;
              font-size: 16px;
              color: ${({ theme }) => theme.text};
              font-weight: 700;
              position: relative;
              word-break: break-all;
              padding: 30px 0.5rem 10px ;
              > p {
                display: flex;
                align-items: center;
                margin-bottom: 0;
                .filter-icon {
                  width: 20px;
                  fill: #7f838a;
                  margin-left: 0.5rem;
                  &.selected {
                    fill: #0193ff;
                  }
                }
              }
              > div {
                position: absolute;
                top: 100%;
                background-color: #ffffff;
                border-color: #929292;
                border-style: solid;
                border-width: 0 1px 1px;
                left: -1px;
                padding: 0.5rem;
                > ul {
                  list-style-type: none;
                  padding: 0;
                  margin: 0;
                  > li {
                    padding: 5px 0;
                    label {
                      display: flex;
                      align-items: center;
                    }
                    input[type="checkbox"] {
                      appearance: none;
                      background-color: #fff;
                      margin: 0;
                      font: inherit;
                      color: currentColor;
                      width: 1.15em;
                      height: 1.15em;
                      border: 0.15em solid currentColor;
                      border-radius: 0.15em;
                      transform: translateY(-0.075em);
                      display: grid;
                      place-content: center;
                      margin-right: 5px;
                    }
                    input[type="checkbox"]::before {
                      content: "";
                      width: 0.65em;
                      height: 0.65em;
                      transform: scale(0);
                      transition: 120ms transform ease-in-out;
                      box-shadow: inset 1em 1em var(--form-control-color);
                    }

                    input[type="checkbox"]:checked::before {
                      transform: scale(1);
                    }
                    input[type="checkbox"]::before {
                      /* ...existing styles */

                      /* Windows High Contrast Mode */
                      background-color: CanvasText;
                    }
                  }
                }
              }
              &.selected {
                background-color: #ffffff;
                border: 1px solid #929292;
                border-radius: 7px 7px 7px 0;
              }
            }
          }
        }
        > tbody {
          > tr {
            > td {
              font-size: 15px;
              font-weight: 400;
              line-height: 21.48px;
              padding: 0 0.5rem;
              word-wrap: break-word;
              max-width: 400px;
            }
            height: 35px;
            &:nth-child(odd) {
              background-color:${({ theme }) => theme.body};
            }
          }
        }
      }
    }
  }
`;
