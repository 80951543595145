export type MetaWidget = {
  group: {
    key?: string;
    title?: string;
    type?: WIDGET_TYPE;
  };
  type: string;
  url: string;
};

export type WidgetFilterConfig = {
  enableFilters: boolean;
  enableInteractions: boolean;
  widgetFilterKey: string;
  filterTitle: string;
};

export type MapConfiguration = {
  location_id: number;
  location_name: string;
  location_coordinates: number[];
  location_data: {
    numeric_count_name: string;
    numeric_count_value: number;
    numeric_count_prefix: string;
    numeric_count_postfix: string;
    numeric_amount_name: string;
    numeric_amount_value: number;
    numeric_amount_prefix: string;
    numeric_amount_postfix: string;
  };
}[];

export type StackedBarChartType = {
  title?: string;
  type: string;
  config: {
    currency_formatter: string;
    visual: {
      value_configs: {
        name: string;
        accessor: string;
        aggregation_type: string;
        is_monetary_value_type: boolean;
      }[];
      category_config: {
        id_accessor: string;
        name: string;
        accessor: string;
      };
      stack_config: {
        id_accessor: string;
        name: string;
        accessor: string;
      };
      show_stack_labels: boolean;
      show_legends: false;
      orientation: string;
    };
  };
  data: any[];
};

export type TickerDataType = {
  pre_text: string;
  previous_value: string;
  title: string;
  type: string;
  value: string;
};

export type TickerContentDataType = {
  name: string;
  value: string;
  value_formatter: string;
  indication: string;
};

export type GroupWidgetMeta = {
  render_type: "single" | "group";
  title: string;
  sub_widgets: { type: WIDGET_TYPE; url: string }[];
  type: WIDGET_TYPE;
  url: string;
};

export type AnalyticsGroupWidgetContextType = {
  fetchData: () => void;
  apiError: any;
  isLoading: boolean;
  widgetData: any;
  widgetMeta: GroupWidgetMeta;
  setWidgetUrl: (url: string) => void;
  onExtendedAClick: () => void;
  enableExtendedAReport: boolean;
  setEnableExtendedReport: (value: boolean) => void;
  isAiSuggestionEnabled: boolean;
  isAiSuggestionLoading: boolean;
  isAiSuggestionLoadError: boolean;
  getAiSuggestion: () => void;
};

export enum WIDGET_TYPE {
  NUM_SM = "NUM_SM",
  TABLE_MD = "TABLE_MD",
  NUM_COUNT_SM = "NUM_COUNT_SM",
  DONUT_MD = "DONUT_MD",
  BAR_MD = "BAR_MD",
  MAP_BUBBLE_LG = "MAP_BUBBLE_LG",
  NUM_DUAL_SM = "NUM_DUAL_SM",
  NUM_TAB_SM = "NUM_TAB_SM",
  NUM_COUNT_TYPE_A_SM = "NUM_COUNT_TYPE_A_SM",
  TEXT_SM = "TEXT_SM",
  LINE_LG = "LINE_LG",
  BAR_STACKED_MD = "BAR_STACKED_MD",
  NUM_TICKER_SM = "NUM_TICKER_SM",
  TREEMAP_LG = "TREEMAP_LG",
  BAR_LINE_COMBO_LG = "BAR_LINE_COMBO_LG",
  LINE_MULTISERIES_LG = "LINE_MULTISERIES_LG",
  DONUT_DUAL_LG = "DONUT_DUAL_LG",
  BAR_MULTISET_MD = "BAR_MULTISET_MD",
  TRENDS_LG = "TRENDS_LG",
  TEXT_MD = "TEXT_MD",
  NUM_MULTI_STAT_SM = "NUM_MULTI_STAT_SM",
  NUM_COST_SM = "NUM_COST_SM",
  NUM_MULTI_COUNT_SM = "NUM_MULTI_COUNT_SM",
  SCATTER_PLOT_LG = "SCATTER_PLOT_LG",
  SANKEY_CHART_LG = "SANKEY_CHART_LG",
  RADIAL_POLAR_BAR_LG = "RADIAL_POLAR_BAR_LG",
  GAUGE_CHART_MD = "GAUGE_CHART_MD",
  CANDLESTICK_CHART_LG = "CANDLESTICK_CHART_LG",
  MAGIC_QUADRANT_LG = "MAGIC_QUADRANT_LG",
  FORECAST_CHART_LG = "FORECAST_CHART_LG",
  FUNNEL_CHART_LG = "FUNNEL_CHART_LG",
  TIMELINE_CHART_LG = "TIMELINE_CHART_LG",
  LIST_MD = "LIST_MD",
  TICKER = "TICKER",
  DORMANT_CHART_MD = "DORMANT_CHART_MD",
  LOST_OPPORTUNITIES_LG = "LOST_OPPORTUNITIES_LG",
  MULTI_SERIES_COMBO_CHART = "MULTI_SERIES_COMBO_CHART",
  SUNBURST_CHART_MD = "SUNBURST_CHART_MD",
  MENU_TREEMAP_LG = "MENU_TREEMAP_LG",
  BAR_MULTISET_SWITCH_MD = "BAR_MULTISET_SWITCH_MD",
  BAR_STACKED_SWITCH_MD = "BAR_STACKED_SWITCH_MD",
  WATERFALL_CHART_LG = "WATERFALL_CHART_LG",
  GAUGE_GROUP_LG = "GAUGE_GROUP_LG",
  STACKED_BAR_CHART = "bar.stacked_bar.v1",
  BS_TABLE_WIDGET = "BS_TABLE_WIDGET",
  SIMPLE_ICON_WIDGET = "SIMPLE_ICON_WIDGET",
}
