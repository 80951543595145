import Markdown from "react-markdown";
import CustomModal from "../../../../components/customModal";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";

const AiCommentaryView = ({ show, closeModal, data }) => {
  const [markdownContent, setMarkdownContent] = useState("");
  let { current: interval } = useRef<NodeJS.Timer>(null);

  const { current: latency } = useRef<number>(50); // in milliseconds
  const { current: splitChar } = useRef(" ");

  useEffect(() => {
    if (data?.result) {
      const splitByChar = data?.result.split(splitChar);
      let currentIndex = 0;
      const processedWords = [];
      interval = setInterval(() => {
        if (currentIndex < splitByChar.length) {
          processedWords.push(splitByChar[currentIndex]);
          setMarkdownContent(processedWords.join(splitChar));
          currentIndex++;
        }
      }, latency);
    }
    return () => {
      // Cleanup
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [data]);

  return (
    <CustomModal show={show} size="lg">
      <CustomModal.CloseButton onClick={closeModal} />
      <CustomModal.Content className="modal-content">
        <StyledModalContent>
          <div className="modal-header">
            <h4 className="modal-title">AI Suggestions</h4>
          </div>
          <div className="ai-result">
            <Markdown>{markdownContent}</Markdown>
          </div>
        </StyledModalContent>
      </CustomModal.Content>
    </CustomModal>
  );
};

export default AiCommentaryView;

const StyledModalContent = styled.div`
  .modal-header {
    position: sticky;
    top: 0px;
    height: 50px;
    background: #fff;

    .modal-title {
      text-decoration: underline;
    }
  }
  .ai-result {
    font-size: 20px;
    padding: 28px;
  }
`;
