import { useEffect, useState } from "react";
import styled from "styled-components";
import CalendarView from "./calendarView";
import MonthPickView from "./monthPickView";
import YearPickView from "./yearPickView";

const InlineDatepicker = ({ currDate, setDate, pickType }) => {
  const [month, setMonth] = useState(new Date().getMonth());
  const [day, setDay] = useState(new Date().getDate());
  const [year, setYear] = useState(new Date().getFullYear());

  const [showCalendarView, setShowCalendarView] = useState(true);
  const [showMonthView, setShowMonthView] = useState(false);
  const [showYearView, setShowYearView] = useState(false);

  useEffect(() => {
    if (currDate) {
      setDay(new Date(currDate).getDate());
      setMonth(new Date(currDate).getMonth());
      setYear(new Date(currDate).getFullYear());
    }
  }, [currDate]);

  const handleDateChange = (date: Date) => {
    setDay(new Date(date).getDate());
    setMonth(new Date(date).getMonth());
    setYear(new Date(date).getFullYear());
  };

  const handleMonthChange = (date: Date) => {
    setMonth(new Date(date).getMonth());
    setYear(new Date(date).getFullYear());
    setShowMonthView(false);
    setShowCalendarView(true);
    setShowYearView(false);
  };

  const handleYearChange = (date: Date) => {
    setYear(new Date(date).getFullYear());
    setShowMonthView(false);
    setShowCalendarView(true);
    setShowYearView(false);
  };

  const handleDateSelect = (selectedDate: Date) => {
    setDate(selectedDate);
  };

  const handleShowMonthView = () => {
    setShowMonthView(true);
    setShowCalendarView(false);
    setShowYearView(false);
  };

  const handleShowYearView = () => {
    setShowMonthView(false);
    setShowCalendarView(false);
    setShowYearView(true);
  };

  return (
    <Wrapper>
      {!showMonthView && !showYearView && showCalendarView ? (
        <CalendarView
          selectedDate={{ year, month, day }}
          onDateChange={handleDateChange}
          onDateSelect={handleDateSelect}
          onShowMonthView={handleShowMonthView}
          pickerType={pickType}
        />
      ) : null}
      {showMonthView && !showYearView && !showCalendarView ? (
        <MonthPickView
          selectedDate={{ year, month, day }}
          onMonthChange={handleMonthChange}
          onShowYearView={handleShowYearView}
        />
      ) : null}
      {!showMonthView && showYearView && !showCalendarView ? (
        <YearPickView
          selectedDate={{ year, month, day }}
          onYearChange={handleYearChange}
        />
      ) : null}
    </Wrapper>
  );
};

export default InlineDatepicker;

const Wrapper = styled.div`
  margin: 15px 0px 0;
  flex: 1;
`;
