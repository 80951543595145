import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "styled-components";
import { setLocationFilter } from "../../state/features/filters/actions";
import { RootState } from "../../state/store";
import { filterLocationsBySelectedDateRange } from "../../helpers/utils";
import DashboardContext from "../../helpers/contexts/dashboardContext";

interface LocationFilterContext {
  partialSelect: boolean;
  setPartialSelect: Dispatch<SetStateAction<boolean>>;
  selectedLocations: any[];
  appliedFilters: number[];
  handleItemSelect: (selectedOptions: any[]) => void;
  handleSubmit: () => void;
  disableSubmit: boolean;
  companyLocations: any[];
  clearSelection: boolean;
  setClearSelection: Dispatch<SetStateAction<boolean>>;
  isMenuOpen: boolean;
  toggleSelectAll: boolean;
  setToggleSelectAll: Dispatch<SetStateAction<boolean>>;
}

export const LocationFilterContext = createContext<
  LocationFilterContext | undefined
>(undefined);

export const useLocationFilterConfig = () => {
  const context = useContext(LocationFilterContext);
  if (context === undefined) {
    throw new Error("useLocation must be used within a LocationFilterProvider");
  }
  return context;
};

interface Props {
  children: ReactNode;
  isMenuOpen: boolean;
  dropdownBtnRef: any;
}

export const LocationFilterProvider = ({ children, dropdownBtnRef, isMenuOpen }: Props) => {
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [clearSelection, setClearSelection] = useState(false);
  const [toggleSelectAll, setToggleSelectAll] = useState(false);
  const [partialSelect, setPartialSelect] = useState(false);

  const appliedFilters = useSelector(
    (state: RootState) => state.filters.locations
  );
  const companyLocations = useSelector((state: RootState) => state.locations);
  const selectedDateRange = useSelector(
    (state: RootState) => state.filters.dates
  );
  const dispatch = useDispatch();
  const { applyFilter } = useContext(DashboardContext);

  const locationsValidInSelectedDateRange = useMemo(() => {
    return filterLocationsBySelectedDateRange(
      companyLocations,
      selectedDateRange
    );
  }, [JSON.stringify(selectedDateRange), JSON.stringify(companyLocations)]);

  const theme = useTheme();
  let disableSubmit = useMemo(
    () => selectedLocations.length === 0,
    [selectedLocations]
  );

  const handleItemSelect = (selectedOptions) => {
    setSelectedLocations(selectedOptions);
  };

  const handleSubmit = () => {
    if (dropdownBtnRef.current) {
      dropdownBtnRef.current.click();
    }
    applyFilter({
      type: "location",
      data: { selectedLocations, dateRange: selectedDateRange },
    });
  };

  return (
    <LocationFilterContext.Provider
      value={{
        partialSelect,
        setPartialSelect,
        selectedLocations,
        appliedFilters,
        disableSubmit,
        companyLocations: locationsValidInSelectedDateRange,
        clearSelection,
        isMenuOpen,
        handleSubmit,
        handleItemSelect,
        setClearSelection,
        toggleSelectAll,
        setToggleSelectAll,
      }}>
      {children}
    </LocationFilterContext.Provider>
  );
};
