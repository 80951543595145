import { REPORT_MAX_ROWS } from "../../../helpers/constants";

export const initialState = {
  apiPath: null as null | string,
  locations: [] as number[],
  timeRange: ["", ""] as [string, string],
  reportTitle: "Report",
  reportData: null,
  totalPages: 0,
  ftsFilterKeys: [],
  columnFilterKeys: [],
  pageLimit: {
    startIndex: 1,
    endIndex: REPORT_MAX_ROWS,
  },
  appliedFtsFilters: [] as any[],
  appliedColumnFilters: [] as any[],
};

export const reducer: (
  state: typeof initialState,
  action: { type: string; payload: any }
) => typeof initialState = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TIME_RANGE":
      return { ...state, timeRange: action.payload };
    case "SET_LOCATIONS":
      return { ...state, locations: action.payload };
    case "SET_BASE_API_PATH":
      if (state.locations.length > 0 && state.timeRange[0]) {
        return {
          ...state,
          apiPath: action.payload,
        };
      }
      return state;
    case "SET_REPORT_DATA":
      let { title, report_data, rows_count, fts_filters, dropdown_filters } =
        action.payload;
      return {
        ...state,
        reportTitle: title,
        reportData: report_data,
        totalPages: Math.ceil(rows_count / REPORT_MAX_ROWS),
        ftsFilterKeys: fts_filters,
        columnFilterKeys: dropdown_filters,
      };
    case "CHANGE_SELECTED_PAGE":
      let startLimit = action.payload * REPORT_MAX_ROWS + 1;
      return {
        ...state,
        pageLimit: {
          startIndex: startLimit,
          endIndex: startLimit + REPORT_MAX_ROWS - 1,
        },
      };
    case "APPLY_FTS_FILTERS":
      let existingFilters: any[] = state.appliedFtsFilters;
      let appliedFilterIndex = existingFilters.findIndex(
        (d) => d.key === action.payload.key
      );
      if (appliedFilterIndex === -1) {
        existingFilters.push(action.payload);
      } else {
        existingFilters[appliedFilterIndex] = action.payload;
      }
      return {
        ...state,
        appliedFtsFilters: existingFilters,
        totalPages: 0,
        pageLimit: { startIndex: 1, endIndex: REPORT_MAX_ROWS },
      };
    case "APPLY_COLUMN_FILTERS":
      let selectedFilter = action.payload[action.payload.length - 1 ?? 0] ?? [];
      let existingColumnFilters = state.appliedColumnFilters;
      let index = state.appliedColumnFilters.findIndex(
        (d) => d.filterKey === selectedFilter.filterKey
      );
      if (index !== -1) {
        let updatedOptions = state.appliedColumnFilters[index];
        updatedOptions["options"] = selectedFilter.options;
        existingColumnFilters[index] = updatedOptions;
        return { ...state, appliedColumnFilters: existingColumnFilters };
      }
      return {
        ...state,
        totalPages: 0,
        pageLimit: { startIndex: 1, endIndex: REPORT_MAX_ROWS },
        appliedColumnFilters: [...state.appliedColumnFilters, selectedFilter],
      };
    case "REMOVE_FTS_FILTER":
      let updatedFtsFilters = state.appliedFtsFilters.filter(
        (d) => d.key !== action.payload.key
      );
      return {
        ...state,
        totalPages: 0,
        pageLimit: { startIndex: 1, endIndex: REPORT_MAX_ROWS },
        appliedFtsFilters: updatedFtsFilters,
      };
    case "REMOVE_COLUMN_FILTERS":
      let updatedFilters = state.appliedColumnFilters;
      let categoryIndex = state.appliedColumnFilters.findIndex(
        (c) => c.filterKey === action.payload.filterCategory.filterKey
      );
      if (categoryIndex !== -1) {
        let category = action.payload.filterCategory;
        category.options = action.payload.filterCategory.options.filter(
          (d) => d.label !== action.payload.widgetFilterData.label
        );
        if (category.options.length > 0) {
          updatedFilters[categoryIndex] = category;
        } else {
          updatedFilters = state.appliedColumnFilters.filter(
            (d) => d.filterKey !== action.payload.filterCategory.filterKey
          );
        }
      }
      return {
        ...state,
        totalPages: 0,
        pageLimit: { startIndex: 1, endIndex: REPORT_MAX_ROWS },
        appliedColumnFilters: updatedFilters,
      };
    case "RESET_APPLIED_FILTERS":
      return {
        ...state,
        totalPages: 0,
        pageLimit: { startIndex: 1, endIndex: REPORT_MAX_ROWS },
        appliedFtsFilters: [],
        appliedColumnFilters: [],
      };
    default:
      return state;
  }
};
