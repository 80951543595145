import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useTable } from "react-table";
import styled, { keyframes } from "styled-components";
import { BsFilterCircle } from "react-icons/bs";
import ColumnFilterDropdown from "./columnFilterDropdown";
import type { ReportData } from "../../../types/reports";
import SelectSubReport from "./selectSubReport";
import { formatCellValue, rearrangeColumns } from "./utils";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { HiOutlineSearch } from "react-icons/hi";
import { formatText } from "../../../helpers/utils";
import { ErrorBoundary } from "react-error-boundary";
import { ImFileEmpty } from "react-icons/im";

type ColumnData = ReportData["report_data"]["columns"];
type RowData = ReportData["report_data"]["rows"];

interface IReportTableContentProps {
  tableData: ReportData["report_data"];
  columnFilters: any[];
  applyColumnFilters: (filters: any) => void;
  isLoading: boolean;
  ftsFilterKeys: string[];
  columnFilterKeys: string[];
  setFtsFilters: (param: { key: string; query: string }) => any;
}

const FtsFilterDropdown = ({ headerTitle, applyFtsFilters, ftsFilterKey }) => {
  const [inputText, setInputText] = useState("");

  const dropdownToggleRef = useRef(null);

  const handleOnChange = (e) => {
    setInputText(e.target.value);
  };

  const handleSubmitClick = () => {
    applyFtsFilters(inputText, ftsFilterKey);
    setInputText("");
    dropdownToggleRef.current?.click();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputText != "") {
      handleSubmitClick();
    }
  };

  const theme = useSelector((state: RootState) => state.theme.mode);

  return (
    <DropdownWrapper>
      <Dropdown as={"span"} className="fts-filter-dropdown">
        <Dropdown.Toggle
          as="span"
          ref={dropdownToggleRef}
          style={{ cursor: "pointer" }}
        >
          {headerTitle} <BsFilterCircle style={{ marginLeft: "5px" }} />
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{
            minWidth: "9.6rem",
          }}
        >
          <FilterContainer
            style={{ background: theme === "light" ? "#f3f3f3" : "#1f1f1f" }}
          >
            <input
              type="text"
              style={{
                border: "none",
                background: theme === "light" ? "#f3f3f3" : "#1f1f1f",
                color: theme === "dark" ? "#f3f3f3" : "#1f1f1f",
                borderColor: theme === "light" ? "#f3f3f3" : "#1f1f1f",
                outline: "none",
              }}
              onChange={handleOnChange}
              size="13"
              value={inputText}
              placeholder="Search..."
              onKeyDown={handleKeyDown}
            />
            <Button
              disabled={!inputText}
              onClick={handleSubmitClick}
              id="searchButton"
              style={{
                background: "transparent",
                cursor: "pointer",
                borderColor: "transparent",
              }}
            >
              <HiOutlineSearch
                style={{ color: theme === "dark" ? "#f3f3f3" : "#1f1f1f" }}
              />
            </Button>
          </FilterContainer>
        </Dropdown.Menu>
      </Dropdown>
    </DropdownWrapper>
  );
};

const DropdownWrapper = styled.span`
  .fts-filter-dropdown {
    // TODO
  }
`;

const ReportTableContent = (props: IReportTableContentProps) => {
  const routeMatch = useParams();

  const [rowData, setRowData] = useState<RowData>([]);
  const [columnData, setColumnData] = useState<ColumnData>([]);

  const [subReportType, setSubReportType] = useState<string | null>(null);
  const [subReportData, setSubReportData] = useState<any>({});

  useEffect(() => {
    setColumnData(rearrangeColumns(props.tableData.columns));
    setRowData(props.tableData.rows);
  }, [props.tableData]);

  useEffect(() => {
    if (props.isLoading) {
      window.scrollTo({ top: 0 });
    }
  }, [props.isLoading]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: columnData, data: rowData });

  const handleFilterApply = (columnName, filterKey, filterData) => {
    let existingFilters = props.columnFilters;
    let index = props.columnFilters.findIndex((d) => d.filterKey === filterKey);
    if (index !== -1) {
      let updatedFilter = props.columnFilters[index];
      updatedFilter["options"] = filterData;
      existingFilters[index] = updatedFilter;
      props.applyColumnFilters(existingFilters);
    } else {
      props.applyColumnFilters([
        ...props.columnFilters,
        { name: columnName, filterKey, options: filterData },
      ]);
    }
  };

  const handleFtsFilterApply = (inputText, key) => {
    props.setFtsFilters({ key, query: inputText });
  };

  const handlePrimaryCellClick = (cell) => {
    setSubReportType("OrderDetails");
    setSubReportData(cell);
  };

  const handleHideSubReport = () => {
    setSubReportType(null);
    setSubReportData({});
  };

  return (
    <Container>
      {props.isLoading ? (
        <div className="table-loading-state">
          Loading <Spinner animation="border" />
        </div>
      ) : null}
      {rows.length || props.isLoading ? (
        <table
          {...getTableProps()}
          className={`${props.isLoading ? "loading" : ""}`}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th>
                  <span>#</span>
                </th>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {props.columnFilterKeys.includes(column.id) ? (
                      <ColumnFilterDropdown
                        columnFilterKey={column.id}
                        headerTitle={formatText(column.header)}
                        appliedFilters={props.columnFilters}
                        setAppliedFilters={handleFilterApply}
                      />
                    ) : props.ftsFilterKeys.includes(column.id) ? (
                      <FtsFilterDropdown
                        headerTitle={formatText(column.header)}
                        applyFtsFilters={handleFtsFilterApply}
                        ftsFilterKey={props.ftsFilterKeys.find(
                          (d) => d === column.id
                        )}
                      />
                    ) : (
                      <span>{formatText(column.header)}</span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  <td>
                    <span>{rowIndex + 1}</span>
                  </td>
                  {row.cells.map((cell, i) => (
                    <td
                      {...cell.getCellProps()}
                      onClick={
                        cell?.column?.primary_key
                          ? () => handlePrimaryCellClick(cell)
                          : () => null
                      }
                      className={`${
                        cell?.column?.primary_key ? "primary" : ""
                      }`}>
                      {formatCellValue(cell, i, columnData)}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <span className="no-reports">
          <ImFileEmpty />
          No Data Available
        </span>
      )}
      {subReportType ? (
        <SelectSubReport
          reportType={subReportType}
          cellData={{
            ...subReportData,
            hideSubReport: () => handleHideSubReport(),
          }}
        />
      ) : null}
    </Container>
  );
};

export default ReportTableContent;

const fadeInTop = keyframes`
  0% {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-50);
    transform: translateY(-50);
    opacity: 1;
  }
`;

const FilterContainer = styled.div`
  margin: 0 5px;
  padding-left: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeInTop} 0.2s;
  input {
    padding: 0.25rem;
  }
  button {
    display: flex;
  }
`;

const Container = styled.div`
  min-height: 600px;
  overflow-x: scroll;
  .table-loading-state {
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 30% 30%;
    position: absolute;
    z-index: 1;
  }
  .no-reports {
    width: 550px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3.5rem;
    font-weight: 700;
    background-color: ${({ theme }) => theme.widget.background};
    gap: 0.5rem;
    margin: 2rem auto;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    svg {
      font-size: 40px;
    }
  }
  table {
    &.loading {
      filter: blur(8px);
      -webkit-filter: blur(8px);
      pointer-events: none;
    }
    width: 100%;
    border-spacing: 0;
    overflow-x: scroll;
    background-color: ${({ theme }) => theme.widget.background};
    > thead {
      height: 55px;
      border-bottom: 1px solid #e4e4e4;
      > tr {
        > th {
          margin-left: 20px;
          font-size: 16px;
          color: ${({ theme }) => theme.text};
          font-weight: 700;
          position: relative;
          white-space: nowrap;
          padding: 0 0.5rem;
          > p {
            display: flex;
            align-items: center;
            margin-bottom: 0;
          }
          > div {
            position: absolute;
            top: 100%;
            background-color: #ffffff;
            border-color: #929292;
            border-style: solid;
            border-width: 0 1px 1px;
            left: -1px;
            padding: 0.5rem;
            > ul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              > li {
                padding: 5px 0;
                label {
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }
      border-bottom: 1px solid #e4e4e4;
    }
    > tbody {
      > tr {
        > td {
          &.primary {
            color: #0094ff;
            font-weight: 700;
            cursor: pointer;
          }
          font-size: 15px;
          font-weight: 400;
          line-height: 21.48px;
          padding: 0 0.5rem;
          white-space: nowrap;
        }
        height: 49px;
        &:nth-child(odd) {
          background-color: ${({ theme }) => theme.body};
        }
      }
    }
  }
`;
