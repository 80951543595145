import { useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../state/store";
import { dateRangePresets } from "./utils";

const DatePresetSection = ({ applyFilters }) => {
  const { dates: selectedDate } = useSelector(
    (state: RootState) => state.filters
  );

  const getClassName = useCallback(
    (presetDates) => {
      return `preset ${
        presetDates[0] === selectedDate[0] && presetDates[1] === selectedDate[1]
          ? "selected"
          : ""
      }`;
    },
    [selectedDate]
  );

  const selectPresetDate = (presetDates) => {
    applyFilters(presetDates);
  };

  return (
    <Wrapper>
      <span className="heading">Quick Selection: </span>
      {dateRangePresets.map((d, i) => (
        <span
          key={i}
          className={getClassName(d.selected_dates)}
          onClick={() => selectPresetDate(d.selected_dates)}>
          {d.title}
        </span>
      ))}
    </Wrapper>
  );
};

export default DatePresetSection;

const Wrapper = styled.div`
  .heading {
    font-size: 16px;
    font-weight: bold;
  }
  .preset {
    font-size: 16px;
    font-weight: 400;
    margin: 0 10px;
    cursor: pointer;
    user-select: none;
    &:hover {
      text-decoration: underline;
    }
    &.selected {
      font-weight: 700;
      color: #14785f;
      pointer-events: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
  padding-bottom: 11px;
  border-bottom: 1px solid ${({ theme }) => theme.border_color};
`;
