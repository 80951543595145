import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { useMemo } from "react";
import { configureUrlWithParams } from "../../../helpers/utils";
import useSWR from "swr";
import offThreadQueryResponse from "../../../helpers/middleware/offThreadQueryResponse";

const useFetchBSAiSuggestions = () => {
  const { filters, company } = useSelector((state: RootState) => state);
  const timezoneOffset = company?.company_details?.time_zone_offset;
  const apiEndpoint = useMemo(() => {
    return configureUrlWithParams(`/api/v1/dar/business-summary/ai`, {
      ...filters,
      shouldUseEpocForTimeRange: true,
      timezoneOffset,
    });
  }, [JSON.stringify(filters)]);

  const { data, error, isValidating, mutate } = useSWR(apiEndpoint, {
    shouldRetryOnError: false,
    revalidateOnMount: true,
    use: [offThreadQueryResponse],
  });
  const retry = () => {
    mutate(undefined);
  };
  return { isError: Boolean(error), data, isLoading: isValidating, retry };
};

export { useFetchBSAiSuggestions };

