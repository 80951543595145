import useSWR from "swr";
import offThreadQueryResponse from "../middleware/offThreadQueryResponse";
import { configureUrlWithParams } from "../utils";

const useFetchAnalyticsFilterData = ({
  dashboard,
  widget,
  filterUrl,
  filters,
  shouldFetchFilterData,
}) => {
  const { data, error, isLoading } = useSWR(
    filterUrl && shouldFetchFilterData
      ? configureUrlWithParams(
          `/api/v1/dar/${dashboard}/${widget}/dropdown-filters/${filterUrl}`,
          filters
        )
      : null,
    { use: [offThreadQueryResponse], revalidateOnFocus: false }
  );
  return { data, error, isLoading };
};

export { useFetchAnalyticsFilterData };
