import styled from "styled-components";
import TickerBanner from "../tickerBanner";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { HiChevronLeft } from "react-icons/hi";
import { useContext, useRef } from "react";
import { DashboardEventsContext } from "../dashboardEvents";
import { IoReload } from "react-icons/io5";
import SecondaryFilterDropdown from "../secondaryFilterDropdown";
import DateRangeSelectDropdown from "../dateRangeSelectDropdown";
import ComparisonSelect from "../comparisonSelect";
import { ComparisonContext } from "../comparison/comparisonProvider";
import ComparisonAdditionalFilters from "../ComparisonFilters/comparisonAdditionalFilters";
import LocationFilterDropdown from "../locationFilterDropdown";
import { useFilterNavigate } from "../../helpers/hooks/useFilterNavigate";
import { useParams } from "react-router-dom";
import { useEventListener } from "usehooks-ts";
import LiveBadge from "./liveBadge";

interface IOverviewHeaderProps {
  dashboardTitle: string;
  analyticsDashboardTitle?: string;
  isComparisonEnabled?: boolean;
  comparisonDimensions?: any;
  shouldFreezeDateRangeSelection?: boolean;
  shouldShowComparisonResults?: boolean;
  refreshData?: () => void;
}

const OverviewHeader = ({
  dashboardTitle = "",
  analyticsDashboardTitle = "",
  isComparisonEnabled = false,
  comparisonDimensions = null,
  shouldFreezeDateRangeSelection = false,
  shouldShowComparisonResults = false,
  refreshData,
}: IOverviewHeaderProps) => {
  const companyLocations = useSelector((state: RootState) => state.locations);
  const { comparison } = useSelector((state: RootState) => state);
  const { isLiveDashboard } = useContext(DashboardEventsContext);
  const comparisonConfigs = useContext(ComparisonContext);

  const pageHeaderRef = useRef<HTMLDivElement>(null);

  const navigateTo = useFilterNavigate();
  const routeMatch = useParams();

  const showSecondaryFilters =
    analyticsDashboardTitle || comparison?.showComparisonResults;
  const isAnalyticsView =
    !shouldShowComparisonResults && analyticsDashboardTitle;
  const isDashboardView =
    !shouldShowComparisonResults && !analyticsDashboardTitle;
  const isComparisonResultsView =
    shouldShowComparisonResults && analyticsDashboardTitle;
  const showLiveBadge =
    !isAnalyticsView || (isAnalyticsView && shouldFreezeDateRangeSelection);
  const showLocationFilters = companyLocations.length > 0;
  const showRefreshDataControl = Boolean(refreshData);

  const handleAnalyticsBackNavigation = () => {
    navigateTo(`/${routeMatch.dashboard}`);
  };

  const handlePageScrollEvent = () => {
    if (pageHeaderRef.current) {
      let header = pageHeaderRef.current;
      let sticky = header.offsetTop;
      if (window.scrollY > sticky - 50) {
        header.classList.add("sticky-header");
      } else {
        header.classList.remove("sticky-header");
      }
    }
  };

  useEventListener("scroll", handlePageScrollEvent);

  return (
    <Wrapper ref={pageHeaderRef}>
      <TickerBanner />
      <div className="content">
        <div className="overview-title-nav-section">
          <span className="nav-section">
            <span
              className={`dashboard-title ${
                isDashboardView && !isAnalyticsView
                  ? "dashboard-only"
                  : !shouldShowComparisonResults
                  ? "show-link"
                  : ""
              }`}
              onClick={handleAnalyticsBackNavigation}>
              <HiChevronLeft
                className={`back-navigation ${
                  !analyticsDashboardTitle ? "hide" : ""
                }`}
              />
              {isDashboardView && !isAnalyticsView
                ? `${dashboardTitle} Overview`
                : dashboardTitle}
            </span>
            {analyticsDashboardTitle ? (
              <span className="analytics-dashboard-title">
                {analyticsDashboardTitle}
              </span>
            ) : null}
          </span>
          <span
            className={`live-controls ${
              isDashboardView && !isAnalyticsView ? "dashboard-only" : null
            }`}>
            {showLiveBadge ? <LiveBadge isLive={isLiveDashboard} /> : null}
            {showRefreshDataControl ? (
              <span className="refresh-data-cta" onClick={() => refreshData()}>
                <IoReload fontSize={"12px"} />
                <span>Refresh Data</span>
              </span>
            ) : null}
          </span>
        </div>
        <div className="overview-controls">
          {showSecondaryFilters ? <SecondaryFilterDropdown /> : null}
          {showLocationFilters ? <LocationFilterDropdown /> : null}
          {!isComparisonResultsView ? (
            <DateRangeSelectDropdown freeze={shouldFreezeDateRangeSelection} />
          ) : null}
          {isAnalyticsView || isComparisonResultsView || isComparisonEnabled ? (
            <div className="comparison">
              <ComparisonSelect />
            </div>
          ) : null}
          {comparisonConfigs?.showComparisonResults &&
          comparisonDimensions &&
          comparisonConfigs ? (
            <ComparisonAdditionalFilters
              filterList={comparisonDimensions}
              context={comparisonConfigs}
            />
          ) : null}
        </div>
      </div>
    </Wrapper>
  );
};

export default OverviewHeader;

const Wrapper = styled.div`
  position: fixed;
  top: 80px;
  z-index: 1000;
  background: ${({ theme }) => theme.body};
  width: 100%;
  height: 150px;
  padding: 0px 50px 10px 50px;
  transition: all 800ms cubic-bezier(0.095, 0.985, 0.045, 0.995);
  .content {
    display: flex;
    flex-direction: row;
    .overview-title-nav-section {
      display: flex;
      flex: 1;
      align-items: center;
      .nav-section {
        .dashboard-title {
          display: block;
          text-align: left;
          font-size: 24px;
          font-weight: 600;
          &.dashboard-only {
            font-size: 2.5rem;
            font-weight: 900;
          }
          &.show-link {
            cursor: pointer;
          }
          .back-navigation {
            position: relative;
            top: -2px;
            cursor: pointer;
            &.hide {
              display: none;
            }
          }
        }
        .analytics-dashboard-title {
          display: block;
          font-size: 2.5rem;
          font-weight: 900;
          text-align: left;
          position: relative;
          top: -10px;
          left: 20px;
        }
      }
      .live-controls {
        display: flex;
        align-items: flex-end;
        position: relative;
        top: 13px;
        left: 33px;
        &.dashboard-only {
          top: 7px;
          left: 28px;
        }
        .refresh-data-cta {
          align-items: center;
          cursor: pointer;
          color: #ffffff;
          border-radius: 20px;
          padding: 3px;
          height: 23px;
          width: 23px;
          background-color: ${({ theme }) => theme.button.primary_color};
          display: flex;
          margin-left: 10px;
          > span {
            margin-left: 2px;
            font-size: 10px;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            width: 0px;
            transition: all 500ms cubic-bezier(0.18, 1, 0.13, 0.965);
          }
          > svg {
            font-size: 15px;
            margin-left: 2px;
            transition: all 500ms cubic-bezier(0.18, 1, 0.13, 0.965);
          }
          &:hover {
            width: 90px;
            > span {
              width: 60px;
            }
            > svg {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
    .overview-controls {
      display: flex;
      align-items: center;
    }
  }
  &.sticky-header {
    height: 100px;
    background: ${({ theme }) => theme.widget.background};
    z-index: 1000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    background: ${({ theme }) => theme.sticky_nav};
    transition: all 500ms cubic-bezier(0.095, 0.985, 0.045, 0.995);
    padding: 0px 50px 20px 50px;
    .content {
      .overview-title-nav-section {
        .nav-section {
          display: flex;
          .dashboard-title {
            &.dashboard-only {
              font-size: 24px;
              &::after {
                content: "";
              }
            }
            &::after {
              content: " /";
            }
            transition: all 800ms cubic-bezier(0.095, 0.985, 0.045, 0.995);
          }
          .analytics-dashboard-title {
            font-size: 24px;
            font-weight: 800;
            text-align: left;
            position: relative;
            top: 0px;
            left: 5px;
            transition: all 800ms cubic-bezier(0.095, 0.985, 0.045, 0.995);
          }
        }
        .live-controls {
          top: 0px;
          left: 15px;
        }
      }
      .overview-controls {
        .secondary-filter-control {
          .filter-icon {
            border: 1px solid ${({ theme }) => theme.border_color};
          }
        }
        .location-select-control {
          .dropdown .dropdown-toggle {
            border: 1px solid ${({ theme }) => theme.border_color};
          }
        }
        .date-range-select-control {
          .date-range-select-toggle {
            border: 1px solid ${({ theme }) => theme.border_color};
          }
        }
      }
    }
  }
`;
