import styled from "styled-components";
import InlineDatepicker from "../inlineDatepicker";

const DatePickerSection = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  return (
    <Wrapper>
      <InlineDatepicker
        pickType="start"
        currDate={startDate}
        setDate={setStartDate}
      />
      <InlineDatepicker
        pickType="end"
        currDate={endDate}
        setDate={setEndDate}
      />
    </Wrapper>
  );
};

export default DatePickerSection;

const Wrapper = styled.div`
  display: inline-flex;
  width: calc(100%);
  > div{
    &:first-child{
      margin-right: 15px;
    }
  }
`;
