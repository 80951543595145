import { useMemo, useState } from "react";
import { configureUrlWithParams } from "../../../helpers/utils";
import useSWR from "swr";
import offThreadQueryResponse from "../../../helpers/middleware/offThreadQueryResponse";

const useFetchExtendedAData = ({
  dashboard,
  dashboardWidget,
  widgetUrl,
  filters,
  additionalFilters,
  shouldFetch,
}) => {
  const [resultData, setResultData] = useState(null);

  const endpoint = useMemo(
    () =>
      configureUrlWithParams(
        `/api/v1/dar/${dashboard}/${dashboardWidget}/${widgetUrl}/extended`,
        { ...filters, additionalFilters }
      ),
    [dashboard, dashboardWidget, widgetUrl, filters, additionalFilters]
  );

  const {
    data,
    isLoading,
    error,
    mutate: retry,
  } = useSWR(shouldFetch ? endpoint : null, {
    use: [offThreadQueryResponse],
    revalidateIfStale: false,
    revalidateOnMount: false,
    onSuccess(data) {
      setResultData(data.extended_data);
    },
  });

  return {
    data,
    isLoading,
    error,
    retry,
  };
};

export { useFetchExtendedAData };
