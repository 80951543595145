import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import { serializeFiltersToUrlParams } from "../../containers/layout/report/utils";
import { RootState } from "../../state/store";
import { ReportContext } from "../contexts/reportContext";
import offThreadQueryResponse from "../middleware/offThreadQueryResponse";

const useFetchColumnFilterData = ({ filterKey }) => {
  const { dates: dateFilters, locations: locationFilters } = useSelector(
    (state: RootState) => state.filters
  );
  const { dashboard, dashboardWidget } = useContext(ReportContext);

  const endpoint = useMemo(() => {
    return serializeFiltersToUrlParams(
      `/api/v1/dar/${dashboard}/${dashboardWidget}/report/dropdown-filters/${filterKey}`,
      { locationFilters, dateFilters }
    );
  }, [locationFilters, dateFilters]);

  const { data, isValidating, mutate, error } = useSWR(endpoint, {
    use: [offThreadQueryResponse],
    revalidateIfStale: false,
    shouldRetryOnError: false,
  });

  const isLoading = useMemo(() => {
    if (!data && isValidating) {
      return true;
    }
    return false;
  }, [data, isValidating]);

  return {
    data: data?.filter_data,
    error,
    isLoading,
  };
};

export default useFetchColumnFilterData;
