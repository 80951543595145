import { BiChevronLeft } from "react-icons/bi";
import { useFilterNavigate } from "../../../helpers/hooks/useFilterNavigate";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const ReportHeaderSection = ({ title }) => {
  const navigateTo = useFilterNavigate();
  const routeMatch = useParams();

  const handleBackNavigation = () => {
    navigateTo(`/${routeMatch.dashboard}/${routeMatch.widget}`);
  };

  return (
    <HeaderWrapper>
      <BiChevronLeft
        style={{ cursor: "pointer" }}
        fontSize={"40px"}
        onClick={handleBackNavigation}
      />
      <span style={{ fontSize: "30px" }}>{title}</span>
    </HeaderWrapper>
  );
};

export default ReportHeaderSection;

const HeaderWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
